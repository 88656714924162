// Typography

@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=f352e82c-9fe3-4db6-afb8-2da2cb9dc4f0");
		@font-face{
				font-family:"HarmoniaSans W01";
				src:url("../Fonts/a5b66773-6a36-469a-8f45-e08448130bf2.eot?#iefix");
				src:url("../Fonts/a5b66773-6a36-469a-8f45-e08448130bf2.eot?#iefix") format("eot"),url("../Fonts/3ba56aa2-c01d-4bfc-aae4-eac13ee2ebbc.woff2") format("woff2"),url("../Fonts/51f1c13a-2c9b-4cba-9cbf-93317cbd3e10.woff") format("woff"),url("../Fonts/7b11ae47-5126-4c06-8f61-fbee4b6a8461.ttf") format("truetype"),url("../Fonts/d6936923-56f5-4284-bb73-3230f8a49594.svg#d6936923-56f5-4284-bb73-3230f8a49594") format("svg");
		}
		@font-face{
				font-family:"HarmoniaSans W01 Bold";
				src:url("../Fonts/afb7b057-b4c8-4f37-9611-323efb16599e.eot?#iefix");
				src:url("../Fonts/afb7b057-b4c8-4f37-9611-323efb16599e.eot?#iefix") format("eot"),url("../Fonts/d13c38b0-30e7-47f0-bc36-e2b7752b59e3.woff2") format("woff2"),url("../Fonts/50cd1762-b122-47c3-bce4-8e385f6a6db0.woff") format("woff"),url("../Fonts/2becde0a-efb7-469b-a992-fcebff7f02bb.ttf") format("truetype"),url("../Fonts/ffa1b212-f1e5-4934-89b5-20243a9f94af.svg#ffa1b212-f1e5-4934-89b5-20243a9f94af") format("svg");
		}
		@font-face{
				font-family:"Trade Gothic LT W01 Bd CnNo-20";
				src:url("../Fonts/f0708d82-47f5-4497-afac-8c5a771250e8.eot?#iefix");
				src:url("../Fonts/f0708d82-47f5-4497-afac-8c5a771250e8.eot?#iefix") format("eot"),url("../Fonts/520ed712-50ad-4591-aa63-b242b83a3694.woff2") format("woff2"),url("../Fonts/c9083b7e-ba26-4e45-8f1b-34ad32e924e5.woff") format("woff"),url("../Fonts/84c568ba-a16d-43bb-ad9f-783ab985108f.ttf") format("truetype"),url("../Fonts/398af540-cbbe-49ac-8de9-7246da632d63.svg#398af540-cbbe-49ac-8de9-7246da632d63") format("svg");
		}

		body, html {
	font-size: 20px;
  font-family: $fontPrimary;
  font-weight: 300;
}

p {
	font-size: 1em;
	line-height: 1.4em;
	margin: 0 0 $spacer/2 0;
}

//buttons

.btn-text {
	opacity: 0;
}

.btn-text:hover {
	border: 1px solid #fff;
}

// links

a {
	color: $colorSecondary;
	text-decoration: none;
	@include hoverTransition(1s);
	font-size: 1.1em;
	line-height: 1.6em;
	position: relative;

	&:hover:after {
		width: 100%;
	}
}

a:after {
	position: absolute;
	left: 0;
	bottom: -3px;
	width: 0;
	height: 3px;
	background-color: $colorSecondary;
	content: "";
	transition: width 0.2s;
}

// Type

h1 {
	color: #fff;
	font-family: $fontSecondary;
	letter-spacing: .03em;
	text-transform: uppercase;
	font-weight: 600;
	line-height: 1.1em;
}

h2 {
	font-size: 1em;
	color: #fff;
	font-family: $fontSecondary;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-weight: 400;
	line-height: 1.2em;
	margin-bottom: $margin/2;
}

h2:after {
	position: inline-block;
	display: block;
	border-bottom: 1px solid $colorSecondary;
	height: 1px;
	width: 10%;
	margin: 0 auto;
	content: "";
	padding-top: $margin/2;
	color: $colorSecondary;
}

h3 {
	font-size: 1.5em;
	color: $colorSecondary;
	font-family: $fontPrimary;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-weight: 400;
	line-height: 1.3em;
	margin: $spacer*2 0;
}

.amp {
	font-family: $fontSecondary;
	color: $colorSecondary;
}

.feature {
	font-weight: 600;
}

strong {
	font-weight: 700;
}
