// Styles

body {
	background-color: #fff;
}

// Header

header {
	height: $margin;
	width: 100%;
	display: block;
	background-color: #fff;
	position: relative;
	z-index: 1000;
}

// Buttons

.button {
  // @include border-radius(5px);
  // background-color: #2eeda3;
	padding: 7px 20px;
	color: #fff;
	font-family: $fontPrimary;
	border: 1px solid #ea7931;
	display: inline-block;
	font-size: 1em;
	text-decoration: none;
	margin: $spacer 0;
	display: inline-block;
	@include hoverTransition(1s);

	&:after {
		display: none;
	}
}

.button:hover {
	border: 1px solid $colorSecondary;
}

.fill:hover {
  color: whitesmoke;
}

.fill:before {
  content: "";
  position: absolute;
  color: #fff;
  background: $colorSecondary;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: top 0.2s ease-in;
}

.fill:hover:before {
  top: 0;
}






// Common Elements

.divider {
	height: 1px;
	background-color: #d7d7d7;
	color: #d7d7d7;
	margin: 30px 0;
	border: none;
}

.right, .left {
	float: none;
}

.center {
	text-align: center;
}


// Animations for transitions in

@keyframes from-right {
    from { transform: translateX(30px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes from-left {
    from { transform: translateX(-30px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes from-above {
    from { transform: translateY(-30px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes from-below {
    from { transform: translateY(30px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

// Scroll arrow
@keyframes jump {
    0% { transform: translateY(0); }
    50% { transform: translateY(20px); }
    100% { transform: translateY(0); }
}

// Scale
@keyframes scale-reveal {
    from { transform: scaleY(0); }
    to { transform: scaleY(1); }
}



// Classes for animations

.from-right {
	opacity: 0;

	-webkit-animation-name: from-right; /* Safari 4.0 - 8.0 */
    animation-name: from-right;
    -webkit-animation-duration: .5s; /* Safari 4.0 - 8.0 */

	animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.1,.3,.7,1); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
}

.from-left {
	opacity: 0;

	-webkit-animation-name: from-left; /* Safari 4.0 - 8.0 */
    animation-name: from-left;
    -webkit-animation-duration: .5s; /* Safari 4.0 - 8.0 */

	animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.1,.3,.7,1); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
}

.from-above {
	opacity: 0;

	-webkit-animation-name: from-above; /* Safari 4.0 - 8.0 */
    animation-name: from-above;
    -webkit-animation-duration: .5s; /* Safari 4.0 - 8.0 */

	animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.1,.7,.9,1); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
}

.from-below {
	opacity: 0;

	-webkit-animation-name: from-below; /* Safari 4.0 - 8.0 */
    animation-name: from-below;
    -webkit-animation-duration: .5s; /* Safari 4.0 - 8.0 */

	animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.1,.7,.9,1); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
}

.delay-xl {
	-webkit-animation-delay: 4s;
	-moz-animation-delay: 4s;
	-ms-animation-delay: 4s;
	animation-delay: 4s;
}

.delay-lg {
	-webkit-animation-delay: 1.5s;
	-moz-animation-delay: 1.5s;
	-ms-animation-delay: 1.5s;
	animation-delay: 1.5s;
}

.delay-md {
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	-ms-animation-delay: 1s;
	animation-delay: 1s;
}

.delay-sm {
	-webkit-animation-delay: .5s;
	-moz-animation-delay: .5s;
	-ms-animation-delay: .5s;
	animation-delay: .5s;
}


.scale-reveal {
	transform: scaleY(0);
	transform-origin: 100% 100%;

	-webkit-animation-name: scale-reveal; /* Safari 4.0 - 8.0 */
    animation-name: scale-reveal;

    -webkit-animation-duration: .5s; /* Safari 4.0 - 8.0 */
	animation-duration: .5s;

    animation-fill-mode: forwards;
    animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
}





// Images on scroll

.thumb {
}

.image {
	width: 100%;
	height: auto;
	margin: 0;
	display: block;
}

/* ANIMATE IN */

.invisible {
	transition: opacity 1s ease, transform 1s ease-out;
	opacity: 0.0;
	transform: translateY(30px);
}

.visible {
	transition: opacity 1s ease, transform 1s ease-out;
	opacity: 1.0;
	transform: translateY(0);
}

/* Menu links */
#links {
	padding: .6em 0 0 0;
	margin: 0;
}

#links li {
	float: left;
	list-style-type: none;
	margin: 0 0 0 1em;
	font-size: .8em;
}

#links li a {
	padding: 5px;
}

.name {
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: .1em;
}

.alt-color {
	color: $colorSecondary;
}

/* Footer */
footer {
	padding: 100px 0;
  color: #fff;
	position: relative;
	z-index: 1000;
}
