// Make sure the charset is set appropriately
@charset "UTF-8";

// Import Variables
@import
"var/variables";

// Import Components
@import
"components/reset",
"components/mixins",
"components/grid",
"components/navigation",
"components/typography",
"components/lists",
"components/basic";

/* Mobile and Screens under 768px */
@import "breakpoints/base";

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
	@import "breakpoints/768up";
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
	@import "breakpoints/992up";
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	@import "breakpoints/1200up";
}





