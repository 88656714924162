/* Layout */

body, html {
}

.one, .two, .three, .four, .five, .six, .seven, .eight, .nine, .ten, .eleven, .twelve {
	width: 100%;
}

.interior {
	padding: $margin 0;
}

.interior h2 {
	font-size: 1.8em;
	margin: $margin/4 0 $margin/2 0;

		&:after {
			padding-top: $margin/3;
		}
}

/* Lists */

ul.horizList {
	li {
		float: none;
		display: block;
		margin: 0 0 $spacer 0;
	}
}

/* Responsive */
.desktop { display: none; }
.mobile { display: inline-block; }


/* Header */

header {
	position: absolute;
	top: 0;
	z-index: 1000;
	display: block;
	width: 100%;
}

.logo-main {
	width: auto;
	height: 1em;
	display: inline-block;
	margin: 1em 0 0 0;
}

#menu-btn {
	float: right;
	margin: 1em 0 0 0;

	p {
		cursor: pointer;
	}
}

.main-logo:hover {
  opacity: 0.6;
}

.main-logo:hover:after {
	display: none;
}

// Splash
// ----------------------------

#splash {
	display: block;
  position: relative;
}

.img-box {
  height: 300px;
	width: 100%;
	background-image: url("../images/splash-image.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left bottom;
	opacity: 0;
  display: block;
	position: relative;
	z-index: 0;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.44,.01,.12,1); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
}

.splash-text {
	opacity: 0;
	position: relative;
	z-index: 1;
	width: 100%;
  top: $margin/2;

	h1 {
		font-size: 2.5em;
	}

}

/* Animation for dots */
@keyframes dotted {
    0% { width: 0; }

    100% { width: 700px; }
}

#dots {
	position: relative;
}

.dotted {
  width: 0;
  height: 15px;
  line-height: $margin/4;
  color: $colorSecondary;
  font-size: .3em;
  letter-spacing: .6em;
  overflow: hidden;
  margin-top: $margin;
  animation-timing-function: cubic-bezier(.44,.01,.12,1); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */

  -webkit-animation-name: dotted; /* Safari 4.0 - 8.0 */
  animation-name: dotted;
  -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
  animation-duration: 1s;

  animation-iteration-count: once;

  animation-fill-mode: forwards;

}

.jump {
  animation: jump 1.5s ease-in-out 3s alternate 2;
  margin-right: 10px;
}

.scroll-arrow {
  color: white;
  position: absolute;
  bottom: $margin;
  right: $margin/4;
	float: right;
}

/* Thumb overlay */

.thumb {
    margin-bottom: $margin;
}

.thumb-content{
  display: block;
	position: relative;
}

.thumb-content img {
  width: 100%;
  height: auto;
}

.img-overlay {
  position: relative;
  top: -5px;
  opacity: 1;

  p {
    position: absolute;;
    z-index: 1000;
    top: 0;
    left: $margin/4;
    color: #fff;
    font-size: .75em;
  }

  p:before {
    position: inline-block;
    display: block;
    border-bottom: 5px solid $colorSecondary;
    height: 1px;
    width: 30px;
    content: "";
    margin-bottom: 10px;
    color: $colorSecondary;
    }

  .thumb-meta {
      font-size: 1em;
      font-family: $fontSecondary;
  }
}

.archive-overlay {
	position: relative;
	top: -15px;
}

/* Single Project */




.interior h2 {
	margin-bottom: $margin/3;
}

#project-info {
	display: block;
	margin: 0 auto;
	margin-top: -$margin;
	background-color: #fff;
	float: none;
	padding: $margin $margin/3;
	position: relative;
	z-index: 1000;

	p {
		padding: 0 0 3em 0;
	}

	p:before {
		display: block;
		border-bottom: 5px solid $colorSecondary;
		height: 1px;
		width: 30px;
		content: "";
		margin-bottom: 10px;
		color: $colorSecondary;
		}

	img {
		margin: 0 0 3em 0;
		border: 1px solid #ccc;
		-webkit-box-shadow: 0px 10px 19px 0px rgba(0,0,0,0.3);
		-moz-box-shadow: 0px 10px 19px 0px rgba(0,0,0,0.3);
		box-shadow: 0px 10px 19px 0px rgba(0,0,0,0.3);
		width: 100%;
		height: auto;
	}

	img.noborder {
		border: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
}

.project-single {
	padding: 3em;

	p {
		padding: 3em 0;
	}
}

.meta p {
	text-align: left;
	padding: 3em 0 0 0;
}

#projectNavigation {

	display: block;
	padding: 30px 0 0 0;

	div {
		width: 50%;
	}

	p {
		font-size: .75em;
	}

	p:before {
		display: none;
	}

	.left {
		float: left;
	}

	.right {
		float: right;
	}

	.previewText {
		font-size: 1em;
	}

	.right-align {
		text-align: right;
	}

}


/* Footer */

footer {
	display: block;
}


/* On the Side */

#on-the-side-title {
	text-align: center;
}

#ontheside {
	background-color: #f9f9f9;
	padding-top: 8em;

	#grid-wrap .bg-col {
		border-left: 1px solid #949494;
		border-right: 1px solid #949494;
	}

	h2 {
		color: $colorPrimary;

		&:after {
			display: none;
		}
	}

	footer {
		background-color: $colorPrimary;
	}

}

.on-the-side-post {
		margin: 6em 0 6em 0;
}

article:before {
  content: "";
  width: 200px;
  height: 1px;
  border-top: 10px solid #000;
  display: block;
  margin: 0 auto;
  margin-bottom: 6em;
}

article img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: .5em 0;
}

article p {
  padding: .5em 3em;
  margin: 0;
}

.date {
	font-family: $fontPrimary;
	font-size: 1em;
	display: inline-block;
	float: left;
}

.meta-cat {
  font-size: .8em;
  padding: 4px 12px;
  background-color: #959595;
  margin: 0 3em 3em 0;
  color: #fff;
}


#stream-callout {
	display: block;
	background-color: #71cac6;
	background-image: URL("../images/icons.svg");
	background-size: contain;
	background-position: right center;
	height: 400px;
	width: 100%;
	padding: 3em 0;
	position: relative;
	margin-top: 4em;
	text-align: center;

	p, h1 {
		display: inline-block;
		padding: 10px 20px;
		margin-bottom: 0;
	}

	h1 {
		background-color: $colorSecondary;
	}

	p {
		background-color: $colorPrimary;
		color: #fff;
	}

	// .button {
	// 	color: $colorSecondary;
	// 	background-color: #fff;
	// }
  //
	// .button:hover {
	// 	color: #fff;
	// }

}

/* About */

#interior-block {
	display: block;
	margin: 0 auto;
	margin-top: -$margin/2;
	background-color: #fff;
	float: none;
	padding: $margin $margin;
	position: relative;
	z-index: 1000;
}

.profile-content {

	.heading {
		font-family:"Trade Gothic LT W01 Bd CnNo-20";
		text-transform: uppercase;
		font-size: 1.2em;
		line-height: 3em;
	}

	p {
		padding: 0 0 2em 0;
	}

	img {
		width: 100%;
		height: auto;
	}

	.column {
		padding: 0;
	}

	.bio-img {
		padding-left: 0;
	}
}
