// Layout
// ---------------------------
// ---------------------------


// Calculate Column Width
// PIXELS
// @function columns($col:1) {
//   $columns: $col*( $site_width/$col_amount );
//   @return $columns;
// }

// Calculate Column Width
// PERCENTAGE
@function columns($col:1) {
  $columns: $col* (100%/$col_amount);
  @return $columns;
}



// Styles

// Box Sizing
*,
*:after,
*:before {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}


/* Layout Columns and Rows */

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
    background-color: $colorPrimary;
}


#content {
    background-color: $colorPrimary;
}

footer {
  display: block;
}

.row {
	max-width: $site_width;
	margin: 0 auto;
	padding: 0;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  height: 100%;
  padding: 0 $margin/4;
}


/* Common */

.clear:after {
   content: ".";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}

.wrap {
  margin: 0 auto;
  padding: 0 $margin/4;
}

/* Grid Overlay */
#grid-wrap {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 0;
  padding: 0 $margin/4;
}

.bg-col {
    opacity: .1;
    height: 100%;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 0 $margin/4;
}


.offset {
  display: inline-block;
  position: relative;
  z-index: 1000;
}
