nav {

	ul {

		padding: 0;
		margin: 0 $margin 0 0;

		li {
			display: inline;
			margin: 0 0 0 30px;
			padding: 7px 15px;
			color: $colorPrimary;
			font-family: $fontPrimary;

			a {
				color: $colorPrimary;
				text-decoration: none;
				@include hoverTransition(1s);
				font-size: 1.1em;
			}
		}

		li:hover {
			cursor: pointer;

			a {
				color: $colorSecondary;
			}
		}
	}
}

/* Small Screen Menu */
/* Off canvas menu */

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    // background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    // padding-top: 60px;

		a {
			text-decoration: none;
			font-size: 2em;
			color: #818181;
			display: block;
			transition: 0.3s;
			// text-transform: uppercase;
			// letter-spacing: .1em;
			// font-weight: 700;
			// padding: 10px 0;

			&:hover:after {
				display: none;
			}

		}

		.closebtn {
		    position: absolute;
		    top: $margin/4;
		    right: $margin/4;
		    font-size: 24px;
				padding: 5px;
				margin-top: -10px;
		}
}


.secondary {
	position: absolute;
	bottom: 2em;

	a {
		font-size: .8em;
		line-height: 2em;
		font-weight: 400;
		text-transform: none;
		letter-spacing: 0;
	}
}

#close-hit {
	height: 3em;
	width: 3em;
	float: right;
	background-color: #fff;
	display: inline-block;
}

.menu-content {
	height: 100%;
	width: 100%;
	background-color: #fff;
	padding-top: 3em;
}
