/* Layout */

.column {
	float: left;
}

.one {
	width: columns(1);
}
.two {
	width: columns(2);
}
.three {
	width: columns(3);
}
.four {
	width: columns(4);
}
.five {
	width: columns(5);
}
.six {
	width: columns(6);
}
.seven {
	width: columns(7);
}
.eight {
	width: columns(8);
}
.nine {
	width: columns(9);
}
.ten {
	width: columns(10);
}
.eleven {
	width: columns(11);
}
.twelve {
	width: columns(12);
}

.wrap {
  padding: 0 $margin/4;
}

/* Grid Overlay */
#grid-wrap {
  // padding: 0 $margin/4;
}

#content {
	width: 100%;
	height: 100%;
	position: relative;
	display: block;
}

/* Common */

.right { float: right; }
.left { float: left; }


/* TYPE */

h1 {
	font-size: 4em;
}

h2 {
	font-size: 2.2em;
}


/* Navigation */
nav {

	ul {

		li {
			float: left;
		}
	}
}

/* Lists */

ul.horizList {
	li {
		float: left;
		display: inline-block;
		margin: 0 30px 0 0;
	}
}

/* Responsive */
.desktop { display: block; }
.mobile { display: none; }

/* Buttons */

.button {
	padding: 10px 20px;
}

/* HEADER */

header { width: 100%;
}

.logo-main {
	float: left;
}



/* Home Page */

#selected-projects {
	display: block;
	position: relative;
}

// SPLASH
#splash {
	display: block;
  position: relative;
  overflow: hidden;
	height: 100%;
	width: 100%;
}

.img-box {
  height: 85%;
	width: 75%;
}

.splash-text {
	opacity: 0;
	position: absolute;
	z-index: 1;
	width: 75%;
  top: $margin*2;

	h1 {
		font-size: 6em;
	}
}

#dots {
	position: absolute;
	bottom: $margin*2;
	left: $margin/2;
}

.dotted {
	line-height: $margin/2;
	font-size: .7em;
}

.scroll-arrow {
  right: $margin;

  p {
    letter-spacing: .2em;
		font-size: 1em;
  }
}

.img-overlay {
  position: absolute;
	z-index: 1000;
  top: 0;
	width: 100%;
	height: 100%;

  opacity: 0;
  transition: .5s ease;
  background-color: $colorPrimary;

	p {
		left: $margin/2;
		font-size: 1em;
	}

	p:before {
		margin-bottom: 20px;
		}

}


.thumb:hover .img-overlay {
  opacity: .8;
}


/* PROJECTS */

.interior h2 {
	font-size: 1.8em;
	margin-bottom: $margin/2;
}

/* SINGLE PROJECT */

#project-content {
	display: block;
}

#project-content .two {
	width: 50%;
}

#project-content img {
		width: 100%;
		height: auto;
		margin: 0 0 $margin 0;
}

#project-info {
	padding: $margin;

	p {
		padding: 0 10% 3em 10%;
	}

	.meta p {
		padding: 0 0 3em 0;
	}
}


#projectNavigation {

	p {
		padding: 0;
	}

}



/* About */

.profile-content {

	p {
		padding: 0 0 2em 3em;
	}

	img.bio-img {
		padding-left: 3em;
	}

}


/* On the Side */

.on-the-side-post {
	margin-left: columns(2);

	img {
		margin-bottom: 2em;
	}
}
