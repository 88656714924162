// LAYOUT
// ---------------------------
// ---------------------------

// Column Variables
$site_width: 100%; // If using pixels, need to alter the function in grid.scss
$col_amount: 12;

$spacer: 1em; // General Spacing Amount

// STYLE
// ---------------------------
// ---------------------------

// Colors
$colorPrimary: #101f3d;
$colorSecondary: #f1592a;
$colorLayout: #fff;

// Typography
// $fontPrimary: "Tungsten A", "Tungsten B", sans-serif;
$fontPrimary: "synthese","Helvetica",sans-serif;
$fontPrimary-bold: "synthese","Helvetica",sans-serif;
$fontSecondary: "Knockout 47 A","Knockout 47 B", sans-serif;

// Spacing
$margin: 3em;

// Hover animations
$time: .5s;
