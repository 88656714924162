/* Plain Lists */

ul {
	padding: $spacer 0 0 0;
	
	li {
		list-style: square;
		padding: 0 0 $spacer 0;
		margin: 0 0 0 30px;
	}
}


/* Horizontal */

ul.horizList {
	
	list-style: none;
	
	li {
		display: block;
		padding: 7px 15px;
		background-color: #949494;
		color: #fff;
	}	
}