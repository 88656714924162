// Mixins

// Box Sizing
// @mixin box-sizing($box-model) {
//   -webkit-box-sizing: $box-model; // Safari <= 5
// 	 -moz-box-sizing: $box-model; // Firefox <= 19
// 		  box-sizing: $box-model;
// }

// Rounded Corners
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Hover Effect
@mixin hoverTransition($time) {
	/*Transition*/
	-webkit-transition: All $time ease;
	-moz-transition: All $time ease;
	-o-transition: All $time ease;
	-ms-transition: All $time ease;
	transition: All $time ease;
}